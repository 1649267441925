<template>
    <div>
      <section class="section blog-single">
        <div class="container">
          <div class="row">
            <div class="col-md-10 m-auto">
              <!-- Single Post -->
              <article class="single-post">
                <!-- Post title -->
                <div class="post-title text-center">
                  <h1>{{form.judul}}</h1>
                  <!-- Tags -->
                  <ul class="list-inline post-tag">
                    <li class="list-inline-item">
                      <!-- <img src="images/testimonial/feature-testimonial-thumb.jpg" alt="author-thumb"> -->
                      <img src="icon3.png" alt="author-thumb">
                    </li>
                    <li class="list-inline-item">
                      <a href="#">{{form.nama}}</a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">{{UMUM.tglConvert(form.createAt)}}</a>
                    </li>
                  </ul>
                </div>
                <!-- Post body -->
                <div class="post-body">
                  <div class="feature-image">
                    <!-- <img class="img-fluid" src="images/blog/single-blog-feature-image.jpg" alt="feature-image"> -->
                  </div>
                  <p v-html="form.isi"></p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
  
      <section class="section related-articles bg-gray">
      <div class="container">
          <div class="row">
              <div class="col-12 title">
                  <h2>Berita Terbaru</h2>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-4 col-md-6" v-for="data in list_data" :key="data.id">
                  <article class="post-sm">
                      <div class="post-thumb">
                          <img class="w-100" :src="file_path+data.file" style="height: 350px">
                      </div>
                      <div class="post-meta">
                          <ul class="list-inline post-tag">
                              <li class="list-inline-item">
                                  <img src="icon3.png">
                              </li>
                              <li class="list-inline-item">
                                  <a href="#">{{data.nama}}</a>
                              </li>
                              <li class="list-inline-item">
                                  {{UMUM.tglConvert(data.createAt)}}
                              </li>
                          </ul>
                      </div>
                      <div class="post-title">
                          <h3><a @click="pushData(data.id)" href="javascript:void(0);">{{data.judul}}</a></h3>
                      </div>
                      <div class="post-details">
                          <p>{{data.gaji}}</p>
                      </div>
                  </article>
              </div>
          </div>
      </div>
  </section>
    </div>
  </template>
  
  <script>
      import UMUM from '../../library/umum';
  
      export default {
  
          components: {},
  
          data() {
              return {
                  id : this.$route.query.id,
                  form : {
                    id : '',
                    judul : '',
                    gaji : '',
                    isi : '',
                    file : '',
                    link : '',
                    userId : '',
                    createdAt : '',
                  },
                  list_data : [],
                  file_old : "",
                  file_path : this.$store.state.url.URL_APP + "uploads/",
  
                  UMUM: UMUM
              }
          },
  
  
          methods: {
  
              getList: function() {
                  fetch(this.$store.state.url.URL_BERITA + "beritaTerbaru", {
                      method: "POST",
                      headers: {
                          "content-type": "application/json"
                      },
                      body: JSON.stringify({
                          
                    })
                  })
                      .then(res => res.json())
                      .then(res_data => {
                          this.list_data = res_data;
                          console.log(res_data);
                  });
              },
  
              getView: function() {
                  fetch(this.$store.state.url.URL_BERITA + "isiBerita", {
                      method: "POST",
                      headers: {
                          "content-type": "application/json"
                      },
                      body: JSON.stringify({
                          id: this.$route.query.id,
                    })
                  })
                      .then(res => res.json())
                      .then(res_data => {
                          var data = res_data[0];
                          
                          this.form.judul = data.judul;
                          this.form.gaji = data.gaji;
                          this.form.isi = data.isi;
                          this.form.file = data.file;
                          this.form.createBy = data.createBy;
                          this.form.createAt = data.createAt;
                          this.form.nama = data.nama;
                          // console.log(res_data)
                  });
              },
  
              pushData : function(id){
                  this.$router.push('/isiBerita?id='+id);
                  this.getView();
              },
  
          },
          
  
  
          mounted() {
              this.getList();
              this.getView();
          },
  
      }
  
  </script>